<template>
  <div class="page-content">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <page-top-bar
        :title="title"
        :subtitle="'Карточка метрики: настройки формирования самой метрики'"
        :buttons="buttons"
    />
    <div class="row">
      <div class="col-lg-8">
        <div class="card border-0 shadow mb-3">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-sm-6">
                <label class="form-label">Инока графика</label>
                <multiselect-input
                    v-model="data.icon"
                    :list="icons"
                    :after-select-option="setIcon"
                />
                <div class="text-danger" v-if="!!errors['icon']">{{ errors['icon'].join(', ') }}</div>
              </div>
              <div class="col-sm-6">
                <label class="form-label">Цвет иноки</label>
                <color-input :disable-alpha="true" v-model="data.icon_color" :position="'bottom left'"
                             @change="setChartView" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                <label class="form-label">Наименование</label>
                <input type="text" class="form-control" placeholder="Активные задачи"
                       v-model="data.name"
                       :disabled="!inArray('name', enabledFields)"
                       :class="{ 'is-invalid': !!errors['name'] }"
                >
                <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
              </div>
              <div class="col-sm-6">
                <label class="form-label">Тип графика</label>
                <multiselect-input
                    v-model="data.type_graph"
                    :list="listTypeGraph"
                    :after-select-option="typeGraph_afterSelectOption"
                />
                <div class="text-danger" v-if="!!errors['type_graph']">{{ errors['type_graph'].join(', ') }}</div>
              </div>
            </div>
            <Transition name="data-type-graph">
              <div v-if="'oneValue' !== this.data.type_graph" class="row mb-3">
                <div class="col-sm-6">
                  <label class="form-label">Кол-во последних значений</label>
                  <input type="number" class="form-control" placeholder="4"
                         v-model="data.count_last_value"
                         :disabled="!inArray('count_last_value', enabledFields)"
                         :class="{ 'is-invalid': !!errors['count_last_value'] }"
                         @input="setChartView"
                  >
                  <div class="text-danger" v-if="!!errors['count_last_value']">{{ errors['count_last_value'].join(', ') }}</div>
                  <div class="text-secondary fs-12px">
                    Кол-во последних значений, которые попадают в график для отображения.
                    Пример: метрика повторяется раз в месяц, Вы указывайте в значении поля 12,
                    в результате на графике вы увидите сведения за последний год
                  </div>
                </div>
                <div v-if="false" class="col-sm-6">
                  <div class="form-control-checkbox">
                    <input type="checkbox" v-model="data.options.isShowSumValues" class="form-check-input" id="dataIsShowSumValues">
                    <label class="form-check-label" for="dataIsShowSumValues">Отображать общую сумму значений в графике</label>
                  </div>
                </div>
              </div>
            </Transition>
            <div>
              <div class="border-bottom pb-1 mt-4 mb-2">Метрики</div>
              <div class="row mb-1" v-for="(metric, i_metric) in data.metrics" :key="'m.' + i_metric">
                <div class="col-sm-5 mb-1">
                  <div class="d-inline-block float-start">
                    <button class="btn btn-sm btn-danger" type="button" @click="data.metrics.splice(i_metric, 1)">
                      <i class="fa-solid fa-trash"></i>
                    </button>
                  </div>
                  <div class="d-inline-block metric-uuid-select">
                    <multiselect-input :list="data.listMetrics" v-model="metric.uuid" />
                  </div>
                </div>
                <div class="col-sm-5 mb-1">
                  <input type="text" @input="setChartView" class="form-control" placeholder="В работе" v-model="metric.name">
                </div>
                <div class="col-sm-2 mb-1">
                  <color-input :disable-alpha="true" v-model="metric.color" :position="'bottom left'"
                               @change="setChartView" />
                </div>
              </div>
              <div class="mt-1 mb-1">
                <button class="btn btn-sm btn-primary" type="button" @click="data.metrics.push({uuid: null, name: '', color: '#886AB5'})">
                  Добавить
                </button>
              </div>
            </div>
            <div v-if="false">
              <div class="border-bottom pb-1 mt-4 mb-2">Указатель на графике</div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <label class="form-label">Наименование</label>
                  <input type="text" class="form-control" placeholder="Надо достигнуть"
                         v-model="data.indicator_name"
                         :disabled="!inArray('indicator_name', enabledFields)"
                         :class="{ 'is-invalid': !!errors['indicator_name'] }"
                  >
                  <div class="text-danger" v-if="!!errors['indicator_name']">{{ errors['indicator_name'].join(', ') }}</div>
                </div>
                <div class="col-md-3">
                  <label class="form-label">Значение</label>
                  <input type="number" class="form-control" placeholder="4"
                         v-model="data.indicator_value"
                         :disabled="!inArray('indicator_value', enabledFields)"
                         :class="{ 'is-invalid': !!errors['indicator_value'] }"
                  >
                  <div class="text-danger" v-if="!!errors['indicator_value']">{{ errors['indicator_value'].join(', ') }}</div>
                </div>
                <div class="col-md-3">
                  <div class="form-control-checkbox">
                    <input type="checkbox" v-model="data.isShowIndicatorToChart" class="form-check-input" id="dataIsShowIndicatorToChart">
                    <label class="form-check-label" for="dataIsShowIndicatorToChart">Отображать на графике</label>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row mt-1">
                    <div class="col-9">
                      Цвет текущего значения, если оно будет <b>выше</b> указателя
                    </div>
                    <div class="col-3">
                      <color-input :disable-alpha="true" v-model="data.options.indicatorColorPlus" :position="'bottom left'"/>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mt-1">
                    <div class="col-9">
                      Цвет текущего значения, если оно будет <b>ниже</b> указателя
                    </div>
                    <div class="col-3">
                      <color-input :disable-alpha="true" v-model="data.options.indicatorColorMinus" :position="'bottom left'"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <metrics-stats-stat-chart-view v-if="isShowChart" :chart-data="data" :is-edit="false" />
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import MultiselectInput from '@/components/form/MultiselectInput'
import ColorInput from 'vue-color-input'
import MetricsStatsStatChartView from '@/components/MetricsStatsStatChartView'

export default {
  name: 'MetricsStatsMetricView',
  components: {MetricsStatsStatChartView, ColorInput, MultiselectInput, ConfirmActionModal, PageTopBar},
  mixins: [formMixin],
  data() {
    return {
      isConfirmDeleteModal: false,

      formName: 'metrics-stats-stat',
      formUrl: {string: '/metrics-stats-stat?uuid=${uuid}'},
      editRoute: {name:  'metrics-stats-stat', params: {uuid: 0}},
      exitRoute: {name: 'metrics-stats-stats'},

      title: '<i class="fa-solid fa-chart-pie"></i> Статистика',

      data: {
        name: '',
        count_last_value: '',
        type_graph: '',
        icon: '',
        icon_color: '',
        indicator_name: '',
        indicator_value: 1,
        metrics: [],
        options: {
          isShowSumValues: false,
          indicatorColorPlus: '#000000',
          indicatorColorMinus: '#000000',
          isShowIndicatorToChart: false,
        },
        cache_values: [],
        listMetrics: [],
      },

      isShowChart: true,

      listTypeGraph: [
        {uuid: 'oneValue', name: 'Последнее значение'},
        {uuid: 'pie', name: '"Пирог"'},
        {uuid: 'line', name: 'Линии'},
        {uuid: 'column', name: 'Колонки'},
      ],

      defaultOptions: {
        isShowSumValues: false,
        indicatorColorPlus: '#000000',
        indicatorColorMinus: '#000000',
        isShowIndicatorToChart: false,
      },

      icons: [
        {uuid: 'fa-solid fa-user', name: '<i class="fa-solid fa-user"></i>'},
        {uuid: 'fa-solid fa-chalkboard-user', name: '<i class="fa-solid fa-chalkboard-user"></i>'},
        {uuid: 'fa-brands fa-medium', name: '<i class="fa-brands fa-medium"></i>'},
        {uuid: 'fa-solid fa-house', name: '<i class="fa-solid fa-house"></i>'},
        {uuid: 'fa-solid fa-magnifying-glass', name: '<i class="fa-solid fa-magnifying-glass"></i>'},
        {uuid: 'fa-solid fa-download', name: '<i class="fa-solid fa-download"></i>'},
        {uuid: 'fa-solid fa-phone', name: '<i class="fa-solid fa-phone"></i>'},
        {uuid: 'fa-solid fa-bars', name: '<i class="fa-solid fa-bars"></i>'},
        {uuid: 'fa-solid fa-comment', name: '<i class="fa-solid fa-comment"></i>'},
        {uuid: 'fa-solid fa-hippo', name: '<i class="fa-solid fa-hippo"></i>'},
        {uuid: 'fa-solid fa-calendar-days', name: '<i class="fa-solid fa-calendar-days"></i>'},
        {uuid: 'fa-solid fa-paperclip', name: '<i class="fa-solid fa-paperclip"></i>'},
        {uuid: 'fa-solid fa-shield-halved', name: '<i class="fa-solid fa-shield-halved"></i>'},
        {uuid: 'fa-solid fa-file', name: '<i class="fa-solid fa-file"></i>'},
        {uuid: 'fa-solid fa-bell', name: '<i class="fa-solid fa-bell"></i>'},
        {uuid: 'fa-solid fa-bolt', name: '<i class="fa-solid fa-bolt"></i>'},
        {uuid: 'fa-solid fa-gift', name: '<i class="fa-solid fa-gift"></i>'},
        {uuid: 'fa-solid fa-money-bill', name: '<i class="fa-solid fa-money-bill"></i>'},
        {uuid: 'fa-solid fa-ruble-sign', name: '<i class="fa-solid fa-ruble-sign"></i>'},
        {uuid: 'fa-solid fa-receipt', name: '<i class="fa-solid fa-receipt"></i>'},
        {uuid: 'fa-solid fa-car', name: '<i class="fa-solid fa-car"></i>'},
        {uuid: 'fa-solid fa-paper-plane', name: '<i class="fa-solid fa-paper-plane"></i>'},
        {uuid: 'fa-solid fa-ticket', name: '<i class="fa-solid fa-ticket"></i>'},
        {uuid: 'fa-regular fa-address-book', name: '<i class="fa-regular fa-address-book"></i>'},
        {uuid: 'fa-solid fa-users', name: '<i class="fa-solid fa-users"></i>'},
        {uuid: 'fa-solid fa-chart-simple', name: '<i class="fa-solid fa-chart-simple"></i>'},
        {uuid: 'fa-solid fa-clock', name: '<i class="fa-solid fa-clock"></i>'},
        {uuid: 'fa-solid fa-school', name: '<i class="fa-solid fa-school"></i>'},
        {uuid: 'fa-solid fa-sitemap', name: '<i class="fa-solid fa-sitemap"></i>'},
        {uuid: 'fa-solid fa-school-circle-xmark', name: '<i class="fa-solid fa-school-circle-xmark"></i>'},
        {uuid: 'fa-solid fa-school-circle-exclamation', name: '<i class="fa-solid fa-school-circle-exclamation"></i>'},
        {uuid: 'fa-solid fa-school-circle-check', name: '<i class="fa-solid fa-school-circle-check"></i>'},
        {uuid: 'fa-solid fa-scale-balanced', name: '<i class="fa-solid fa-scale-balanced"></i>'},
        {uuid: 'fa-solid fa-ranking-star', name: '<i class="fa-solid fa-ranking-star"></i>'},
        {uuid: 'fa-solid fa-list-check', name: '<i class="fa-solid fa-list-check"></i>'},
        {uuid: 'fa-solid fa-id-card', name: '<i class="fa-solid fa-id-card"></i>'},
        {uuid: 'fa-solid fa-graduation-cap', name: '<i class="fa-solid fa-graduation-cap"></i>'},
        {uuid: 'fa-solid fa-chart-line', name: '<i class="fa-solid fa-chart-line"></i>'},
      ]
    }
  },
  methods: {
    afterLoad: function () {
      if (this.empty(this.data.options)) {
        this.data.options = JSON.parse(JSON.stringify(this.defaultOptions))
      }
      this.setChartView()
    },
    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },

    typeGraph_afterSelectOption: function () {
      if ('oneValue' === this.data.type_graph) {
        this.data.count_last_value = 1
      }
      this.setChartView()
    },

    setChartView: function () {
      this.data.cache_values.labels = []
      for (let i = 1; i <= this.data.count_last_value; i++) {
        this.data.cache_values.labels.push(i + '')
      }
      this.data.cache_values.data = []
      for (let m in this.data.metrics) {
        let item = {
          label: this.data.metrics[m].name + '',
          color: this.data.metrics[m].color + '',
          values: [],
        }
        for (let i = 1; i <= this.data.count_last_value; i++) {
          item.values.push(Math.floor(Math.random() * (10 - 2 + 1) + 2))
        }

        this.data.cache_values.data.push(item)
      }
    },

    setIcon: function () {
      let self = this
      self.isShowChart = false
      setTimeout(function () {
        self.isShowChart = true
      }, 100)
    },

    close: function () {
      this.isConfirmDeleteModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
.data-type-graph-enter-active,
.data-type-graph-leave-active {
  transition: opacity 0.5s ease;
}

.data-type-graph-enter-from,
.data-type-graph-leave-to {
  opacity: 0;
}

.metric-uuid-select {
  width: calc(100% - 32px);
  padding-left: 8px
}
</style>

<template>
  <div>
    <div v-if="'oneValue' === type" class="card-body">
      <div v-if="!empty(datasets.values) && !empty(datasets.values[0])"
           class="text-end text-center"
           :style="'font-size:'+(datasets.values[0] <= 100 ? '76' : (datasets.values[0] < 10000 ? '46' : (datasets.values[0] < 1000000 ? '30' : '20')))+'px;white-space:nowrap;color:'+datasets.color"
      >
        {{ new Intl.NumberFormat("ru").format(datasets.values[0]) }}
      </div>
    </div>
    <div v-if="'pie' === type" class="card-body">
      <pie-chart :data="datasets" :colors="colors" />
    </div>
    <div v-if="'line' === type" class="card-body">
      <line-chart :data="datasets" :colors="colors" />
    </div>
    <div v-if="'column' === type" class="card-body">
      <column-chart :data="datasets" :colors="colors" />
    </div>
  </div>
</template>
<script>

export default {
  name: 'ChartView',
  components: {},
  props: {
    type: {
      type: String,
      required: true
    },
    chartData: {
      type: Array,
      required: true
    },
    chartXLabels: {
      type: Array,
      required: false
    },
    chartOptions: {
      type: Object,
      required: true
    },
  },
  watch: {
    chartData: function () {
      this.setData()
    },
    type: function () {
      this.setData()
    },
  },
  data() {
    return {
      datasets: {},
      colors: null,
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData: function () {
      let datasets = {}
      let colors = []
      switch (this.type) {
        case 'oneValue':
          datasets = this.chartData[0]
          break
        case 'pie':
          datasets = []
          for (let i in this.chartData) {
            datasets.push([this.chartData[i].label, this.chartData[i].values[0]])
            if (false === this.empty(this.chartData[i].color)) {
              colors.push(this.chartData[i].color)
            }
          }
          break
        case 'column':
        case 'line':
          datasets = []
          for (let i in this.chartData) {
            let values = {}
            for (let x in this.chartXLabels) {
              values[this.chartXLabels[x]] = this.chartData[i].values[x]
            }

            if (false === this.empty(this.chartData[i].color)) {
              colors.push(this.chartData[i].color)
            }

            datasets.push(
                {
                  name: this.chartData[i].label,
                  data: values,
                }
            )
          }
          break
      }

      this.colors = false === this.empty(colors) ? colors : null
      this.datasets = datasets
    },
  }
}
</script>

<style scoped>
</style>

<template>
    <div>
      <base-modal :show="isExitModal" title="Внимание!" :footer="true" @close="close">
        <template v-slot:body>
          <div class="py-3">
            Выйти из профиля?
          </div>
        </template>
        <template v-slot:footer>
          <a @click="exit" class="btn btn-primary sm-w-100 mt-1">Выход</a>
          <a class="btn btn-light sm-w-100 ms-md-2 mt-1" @click="close">Отмена</a>
        </template>
      </base-modal>
      <div class="app-nav-panel-top">
        <div class="dropdown float-end">
          <div
              class="p-1 app-nav-panel-top-avatar"
              :class="{'bg-danger': !lanStatus}"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
          >
            {{ globalVar.app.user.avatarText }}
          </div>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#" @click="toRoute({name: 'profile'})">
              <i class="fa-solid fa-user me-3"></i>Профиль
            </a></li>
            <li><a class="dropdown-item" href="#" @click="toRoute({name: 'workplace-parameters'})">
              <i class="fa-solid fa-desktop me-3"></i>Параметры рабочего места
            </a></li>
            <!--
            <li><a class="dropdown-item" href="#">
              <i class="fa-solid fa-gear me-3"></i>Настройки
            </a></li> -->
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#" @click="openExitModal">
              <i class="fa-solid fa-arrow-right-from-bracket me-3"></i>Выйти
            </a></li>
            <li><hr class="dropdown-divider"></li>
            <li><div class="dropdown-item-text text-secondary fs-10px text-end">
              aVIcrm {{ globalVar.app.v }}
            </div></li>
          </ul>
        </div>
        <a class="p-1 app-nav-panel-top-notification float-end"
           data-bs-toggle="offcanvas"
           href="#offcanvasNotification"
           role="button"
           :class="{'there-are-notifications': appPushes.length > 0}"
           aria-controls="offcanvasNotification">
          <i class="fa-solid fa-bell text-secondary"></i>
        </a>

        <transition name="indicator-new-push">
          <div v-if="isShowIndicatorNewPush" class="toast-container position-fixed top-0 end-0 p-3">
            <div class="toast text-bg-primary fade show"
                 role="alert"
                 aria-live="assertive">
              <div class="toast-body">
                Новое уведомление
              </div>
            </div>
          </div>
        </transition>

      </div>
      <div class="app-menu" :class="appMenu.isOpened ? 'open' : ''">
        <div class="logo-details">
          <span :title="'v' + globalVar.app.v" class="icon mx-2">
          </span>
          <span class="app-menu-button"
              :class="appMenu.isOpened ? 'bx-menu-alt-right' : 'bx-menu'"
              @click="btnAppMenuOpen_click"
          >
              <i class="fa-solid fa-grip-lines" />
            <span v-if="false" class="app-menu-indicator-events bg-danger"></span>
          </span>
        </div>
        <div class="menu-container">
          <div class="menu-scroll">
            <ul>
              <li class="cursor-pointer" v-for="(menuItem, index) in appMenu.items"  :key="'appmenu_' + index">
                <a @click="clickItemMenu(menuItem)" :class="$route.meta.menu === menuItem.metaMenu ? 'active' : ''">
                  <span class="app-menu-icon"><i :class="menuItem.icon" /></span>
                  <span class="links_name">{{ menuItem.name }}</span>
                </a>
                <span v-if="menuItem.badge !== undefined && menuItem.badge > 0" class="badge badge-danger">{{ menuItem.badge }}</span>
                <span class="tooltip">{{ menuItem.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="offcanvas offcanvas-end notification-offcanvas" tabindex="-1" id="offcanvasNotification" aria-labelledby="offcanvasNotificationLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNotificationLabel">Уведомления</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Закрыть"></button>
        </div>
        <div class="offcanvas-body">
            <div v-for="(push, i_push) in appPushes" class="mb-3"
                 :class="push.class"
                 :key="'app.push.' + i_push">
              <div class="text-secondary fs-12px mb-1"><i class="fa-regular fa-clock"></i> {{ push.created_at }}</div>
              <div class="border border-radius-10 px-2 py-1">
                <div class="d-flex">
                  <div class="toast-body overflow-y-hidden">
                    <span v-html="push.message"></span>
                  </div>
                  <button
                      type="button"
                      class="btn-close me-0 m-auto"
                      aria-label="Закрыть"
                      @click="removePush(push, i_push)"
                  />
                </div>
              </div>
            </div>
        </div>
      </div>

      <section
          class="app-layout-route-content"
          :class="{'content-left-menu-open': appMenu.isOpened}"
          @click="content_click"
      >
        <slot/>
      </section>
    </div>
</template>

<script>
import BaseModal from '../../components/BaseModal'

export default {
  name: "AppLayout",
  components: {BaseModal},
  data() {
    return {
      lanStatus: true,
      isExitModal: false,

      appPushes: [],
      isShowIndicatorNewPush: false,

      appMenu: {
        isOpened: localStorage.appMenuMenuIsOpened,
        items: [],
      },
    }
  },
  mounted: function() {
    this.setAppMenuItems()
    this.emitter.on('app', this.appLayout_app)
    window.addEventListener("resize", this.windowResize)
    this.windowResize()
    this.updateAppPushes()
  },
  unmounted: function() {
    this.emitter.off('app', this.appLayout_app)
    window.removeEventListener("resize", this.windowResize)
  },
  methods: {
    appLayout_app: function (data) {
      switch (data.event) {
        case 'status.update':
          this.lanStatus = this.globalVar.app.lan.status
          break
        case 'push.new':
          this.newPushAppPushes()
          break
        case 'push.update':
          this.updateAppPushes()
          break
      }
    },
    setAppMenuItems: function() {
      let self = this
      let items = [
        {route: {name: 'dashboard'}, metaMenu: 'dashboard', name: 'Рабочий стол', icon: 'fa-solid fa-border-all'},
        {
          route: {name: 'sys-contractors'},
          metaMenu: 'sys-contractors',
          name: '[SYS] Контрагенты',
          icon: 'fa-solid fa-dollar-sign'
        },
        {
          route: {name: 'sys-businesses'},
          metaMenu: 'sys-businesses',
          name: '[SYS] Бизнесы',
          icon: 'fa-solid fa-landmark'
        },
        {route: {name: 'sys-users'}, metaMenu: 'sys-users', name: '[SYS] Пользователи', icon: 'fa-solid fa-users-line'},

        {route: {name: 'calendar'}, metaMenu: 'calendar', name: 'Календарь', icon: 'fa-solid fa-calendar-week'},
        {
          route: {name: 'crm-contractors'},
          routeNames: ['crm-contractors', 'crm-deals'],
          metaMenu: 'crm',
          name: 'CRM',
          icon: 'fa-solid fa-handshake-simple'
        },
        //{route: {name: 'crm-deals'}, metaMenu: 'crm', name: 'CRM', icon: 'fa-solid fa-handshake-simple'},
        {
          route: {name: 'school-run-processes'}, routeNames: [
            'school-run-processes',
            'school-done-processes',
            'school-planning',
            'school-setting',
            'school-analytics-timetables',
            'school-analytics',
            'school-processes',
            'school-courses',
            'school-students'
          ], metaMenu: 'school', name: 'Обучение', icon: 'fa-solid fa-graduation-cap'
        },
        {route: {name: 'events'}, metaMenu: 'events', name: 'События', icon: 'fa-solid fa-timeline'},
        {route: {name: 'tasks'}, metaMenu: 'tasks', name: 'Задачи', icon: 'fa-solid fa-list-check'},
        {route: {name: 'notes'}, metaMenu: 'notes', name: 'Заметки', icon: 'fa-solid fa-note-sticky'},
        {route: {name: 'metrics-stats-stats'}, metaMenu: 'metrics-stats', name: 'Статистика', icon: 'fa-solid fa-chart-pie'},
        {
          route: {name: 'personnel-structures'},
          metaMenu: 'personnel-structures',
          name: 'Штатная структура',
          icon: 'fa-solid fa-sitemap'
        },
        {route: {name: 'users'}, metaMenu: 'users', name: 'Пользователи', icon: 'fa-solid fa-users'},
      ]
      this.appMenu.items = items.filter(function (item) {
        return true === self.hasRoute(item.route)
      })
    },
    hasRoute(route) {
      let isShow = false
      this.globalVar.app.user.routes.forEach(function (userRoute) {
        if (userRoute === route.name) {
          isShow = true
        }
        if (undefined !== route.routeNames) {
          route.routeNames.forEach(function (routeName) {
            if (userRoute === routeName) {
              isShow = true
            }
          })
        }
      })
      return isShow
    },
    btnAppMenuOpen_click() {
      this.appMenu.isOpened = !this.appMenu.isOpened
      localStorage.appMenuMenuIsOpened = this.appMenu.isOpened
    },
    content_click() {
      if (this.appMenu.isOpened && window.innerWidth < 576) {
        this.appMenu.isOpened = false
        localStorage.appMenuMenuIsOpened = this.appMenu.isOpened
      }
    },
    windowResize() {
      if (this.appMenu.isOpened && window.innerWidth < 767) {
        this.appMenu.isOpened = false
        localStorage.appMenuMenuIsOpened = this.appMenu.isOpened
      }
    },
    clickItemMenu(menuItem) {
      if (this.appMenu.isOpened && window.innerWidth <= 767) {
        this.appMenu.isOpened = false
        localStorage.appMenuMenuIsOpened = this.appMenu.isOpened
      }

      this.toRoute(menuItem.route)
    },

    newPushAppPushes() {
      this.isShowIndicatorNewPush = true
      let self = this
      setTimeout(function () {
        self.isShowIndicatorNewPush = false
      }, 500)
    },

    updateAppPushes() {
      if (localStorage.appPushes === undefined) {
        localStorage.appPushes = JSON.stringify([])
      }
      this.appPushes = JSON.parse(localStorage.appPushes)
    },
    removePush(push, index) {
      this.appPushes.splice(index, 1);
      localStorage.appPushes = JSON.stringify(this.appPushes)
    },

    openExitModal() {
      this.isExitModal = true
      this.emitter.emit('modal', {event: 'open'})
    },
    exit: function () {
      this.globalVar.app.user.isAuth = false
      this.emitter.emit('app', {event: 'logout'})
    },
    close() {
      this.isExitModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
.app-nav-panel-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 66px;
  padding: 14px 1rem;

  background: var(--app-nav-panel-bg);
  color: var(--app-nav-panel-color);

  z-index: 9000;
}

.app-nav-panel-top-avatar {
  position: relative;
  width: 38px;
  height: 38px;
  text-align: center;
  font-size: 19px;
  background: var(--app-variant-color-item-7-bg);
}

@media screen and (max-width: 575px) {
  .app-nav-panel-top-avatar {
    margin-left: 16px;
  }

}

.indicator-new-push-enter-active {
  animation: indicator-new-push-in .4s;
}
.indicator-new-push-leave-active {
  animation: indicator-new-push-in .4s reverse;
}
@keyframes indicator-new-push-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.offcanvas-list-move,
.offcanvas-list-enter-active,
.offcanvas-list-leave-active {
  transition: all 0.5s ease;
}

.offcanvas-list-enter-from,
.offcanvas-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.there-are-notifications:before {
  content: "";
  display: block;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: rgb(var(--bs-danger-rgb));
  border: 2px solid var(--app-nav-panel-bg);
}

.app-nav-panel-top-notification {
  position: relative;
  width: 38px;
  height: 38px;
  text-align: center;
  font-size: 19px;
}

.app-layout-route-content {
  position: fixed;
  top: 66px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;

  background: var(--app-bg);
  color: var(--app-color);
}


@media screen and (max-width: 575px) {
  .app-nav-panel-top {
    height: 50px;
    padding: 6px 0.4rem;
  }

  .app-layout-route-content {
    top: 50px;
  }
}

.notification-offcanvas {
  z-index: 9003;
}

.offcanvas-backdrop {
  z-index: 9002;
}

.menu-scroll {
  overflow-x: hidden;
}

.app-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  height: 100%;
  min-height: min-content;
  width: 78px;
  background: rgb(var(--bs-primary-rgb));
  z-index: 9001;
  transition: all 0.5s ease;
}

.app-menu.open {
  width: 250px;
}

.app-menu .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 6px 14px 0 14px;
}
.app-menu .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.app-menu.open .logo-details .icon {
  opacity: 1;
}
.app-menu .logo-details .app-menu-button {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.app-menu.open .logo-details .app-menu-button {
  text-align: right;
}
.app-menu .app-menu-button {
  color: var(--bs-btn-color);
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.app-menu .menu-container {
  display: flex ;
  flex-direction:column;
  justify-content: space-between;
  flex-grow: 1;
  max-height: calc(100% - 60px);
}
.app-menu .menu-container {
  overflow-y: auto;
  height: calc(100% - 60px);
  margin: 0 14px;
}
.app-menu .menu-container::-webkit-scrollbar {
  display:none;
}

.app-menu ul {
  padding-left: 0;
}

.app-menu li {
  position: relative;
  margin: 8px 0;
  list-style: none;
  cursor: pointer;
}

.app-menu li .badge {
  position: absolute;
  top: 16px;
  right: 10px;
}

.app-menu:not(.open) li .badge {
  top: 14px;
  right: 8px;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  color: rgba(255,255,255,0);
}

.app-menu li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #e4e9f7;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.app-menu li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.app-menu.open li .tooltip {
  display: none;
}
.app-menu li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: rgb(var(--bs-primary-rgb));
}
.app-menu li a.active,
.app-menu li a:hover {
  background: #ffffff;
}
.app-menu li a .links_name {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.app-menu.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}
.app-menu li a.active .links_name,
.app-menu li a.active .app-menu-icon,
.app-menu li a:hover .links_name,
.app-menu li a:hover .app-menu-icon {
  transition: all 0.5s ease;
  color: rgb(var(--bs-primary-rgb));
}
.app-menu .app-menu-icon {
  color: #ffffff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.app-menu li .app-menu-icon {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

.app-menu-open-bg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

@media screen and (max-width: 575px) {
  .app-menu li .tooltip {
    display: none;
  }

  .app-menu {
    height: 38px;
    margin: 6px 0.4rem;
    border-radius: 10px;
  }

  .app-menu .logo-details {
    height: 38px;
  }

  .app-menu.open {
    height: 100%;
    margin: initial;
    border-radius: initial;
  }

  .app-menu:not(.open) .app-menu-indicator-events {
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    right: 12px;
    top: 20px;
    border-radius: 3px;
  }

  .app-menu-open-bg {
    display: block;
  }
}

@media screen and (min-width: 576px) {
  .app-layout-route-content {
    left: 78px;
  }
}


.app-layout-route-content.content-left-menu-open {
  left: 250px;
}

@media screen and (max-width: 575px) {
  .app-layout-route-content.content-left-menu-open {
    left: 0;
  }
}
</style>

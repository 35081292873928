<template>
  <div class="page-content">
    <confirm-action-modal
        :show="isConfirmDeleteModal"
        :text="'Удалить ' + data.name + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="formDelete"
        @close="close"
    />
    <page-top-bar
        :title="title"
        :subtitle="'Карточка метрики: настройки формирования самой метрики'"
        :buttons="buttons"
    />
    <div class="card border-0 shadow mb-3">
      <div class="card-body">
        <div class="mb-3">
          <label class="form-label">Наименование</label>
          <input type="text" class="form-control" placeholder="Активные задачи"
                 v-model="data.name"
                 :disabled="!inArray('name', enabledFields)"
                 :class="{ 'is-invalid': !!errors['name'] }"
          >
          <div class="text-danger" v-if="!!errors['name']">{{ errors['name'].join(', ') }}</div>
        </div>
        <div class="mb-3">
          <label class="form-label">Описание</label>
          <input type="text" class="form-control" placeholder="Общее число активных задач"
                 v-model="data.description"
                 :disabled="!inArray('description', enabledFields)"
                 :class="{ 'is-invalid': !!errors['description'] }"
          >
          <div class="text-danger" v-if="!!errors['description']">{{ errors['description'].join(', ') }}</div>
        </div>
        <div class="mb-3">
          <label class="form-label">Интервал повторения</label>
          <multiselect-input
              v-model="data.interval"
              :disabled="!inArray('interval', enabledFields)"
              :class-name="{ 'is-invalid': !!errors['interval'] }"
              :list="data.listIntervals"
          />
          <div class="text-danger" v-if="!!errors['interval']">{{ errors['interval'].join(', ') }}</div>
        </div>
        <div class="mb-3">
          <label class="form-label">Объект метрики</label>
          <multiselect-input
              v-model="data.count_purpose"
              :disabled="!inArray('count_purpose', enabledFields)"
              :class-name="{ 'is-invalid': !!errors['count_purpose'] }"
              :list="data.listCountPurpose"
              :after-select-option="countPurpose_afterSelectOption"
          />
          <div class="text-danger" v-if="!!errors['count_purpose']">{{ errors['count_purpose'].join(', ') }}</div>
        </div>
        <div class="mb-3">
          <label class="form-label">Условия</label>
          <search-query-builder
              :fields="data.listQueryFieldsByCountPurpose"
              :disabled="!inArray('query', enabledFields)"
              v-model="data.query"
              :name-templates="''"
            />
          <div class="text-danger" v-if="!!errors['query']">{{ errors['query'].join(', ') }}</div>
        </div>
        <div class="mb-3">
          <label class="form-label">Количественный результат</label>
          <multiselect-input
              v-model="data.sum_field"
              :disabled="!inArray('sum_field', enabledFields)"
              :class-name="{ 'is-invalid': !!errors['sum_field'] }"
              :list="data.listSumFieldsByCountPurpose"
          />
          <div class="text-danger" v-if="!!errors['sum_field']">{{ errors['sum_field'].join(', ') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formMixin} from '@/mixins/formMixin'
import PageTopBar from '@/components/PageTopBar'
import ConfirmActionModal from '@/components/ConfirmActionModal'
import MultiselectInput from '@/components/form/MultiselectInput'
import SearchQueryBuilder from '@/components/searchQueryBuilder/SearchQueryBuilder'

export default {
  name: 'MetricsStatsMetricView',
  components: {SearchQueryBuilder, MultiselectInput, ConfirmActionModal, PageTopBar},
  mixins: [formMixin],
  data() {
    return {
      isConfirmDeleteModal: false,

      formName: 'metrics-stats-metric',
      formUrl: {string: '/metrics-stats-metric?uuid=${uuid}'},
      editRoute: {name:  'metrics-stats-metric', params: {uuid: 0}},
      exitRoute: {name: 'metrics-stats-metrics'},

      title: '<i class="fa-solid fa-swatchbook"></i> Метрика',

      data: {
        name: '',
        description: '',
        interval: null,
        count_purpose: null,
        query: {},
        sum_field: null,
        listCountPurpose: [],
        listIntervals: [],
        listQueryFields: [],
        listQueryFieldsByCountPurpose: [],
        listSumFields: [],
        listSumFieldsByCountPurpose: [],
      },
    }
  },
  methods: {
    afterErrorSave: function() {
      if (false === this.empty(this.generalError)) {
        this.app_addPush('Ошибка сохранения: ' + this.generalError)
      }
    },
    openModalDelete: function () {
      this.isConfirmDeleteModal = true
      this.emitter.emit('modal', {event: 'open'})
    },

    countPurpose_afterSelectOption: function () {
      this.data.listQueryFieldsByCountPurpose = JSON.parse(
          JSON.stringify(
              this.data.listQueryFields[this.data.count_purpose]
          )
      )
      this.data.listSumFieldsByCountPurpose = JSON.parse(
          JSON.stringify(
              this.data.listSumFields[this.data.count_purpose]
          )
      )
    },

    close: function () {
      this.isConfirmDeleteModal = false
      this.emitter.emit('modal', {event: 'close'})
    },
  }
}
</script>

<style scoped>
.textarea-description {
  height: 100%;
  min-height: 300px;
}
</style>

import { createRouter, createMemoryHistory } from 'vue-router'

import AuthView from './views/AuthView'

import DashboardView from './views/app/dashboard/DashboardView'
import ProfileView from './views/app/profile/ProfileView'
import WorkplaceParametersView from './views/app/workplaceParameters/WorkplaceParametersView'

import SysContractorsList from './views/app/contractor/SysContractorsList'
import SysContractorView from './views/app/contractor/SysContractorView'

import SysBusinessesList from './views/app/business/SysBusinessesList'
import SysBusinessView from './views/app/business/SysBusinessView'

import SysUsersList from './views/app/user/SysUsersList'
import SysUserView from './views/app/user/SysUserView'

import CalendarView from './views/app/calendar/CalendarView'

import PersonnelStructuresList from './views/app/personnelStructure/PersonnelStructuresList'
import PersonnelStructureView from './views/app/personnelStructure/PersonnelStructureView'

import UsersList from './views/app/user/UsersList'
import UserView from './views/app/user/UserView'

import CrmContractorsList from './views/app/crm/contractor/CrmContractorsList'
import CrmContractorView from './views/app/crm/contractor/CrmContractorView'

import SchoolRunProcessesList from './views/app/school/process/SchoolRunProcessesList'
import SchoolRunProcessView from './views/app/school/process/SchoolRunProcessView'
import SchoolDoneProcessesList from './views/app/school/process/SchoolDoneProcessesList'
import SchoolDoneProcessView from './views/app/school/process/SchoolDoneProcessView'
import SchoolPlanningView from './views/app/school/SchoolPlanningView'
import SchoolPlanningProcessView from './views/app/school/process/SchoolPlanningProcessView'
import SchoolAnalyticsTimetablesView from './views/app/school/SchoolAnalyticsTimetablesView'
import SchoolAnalyticsView from './views/app/school/analytics/SchoolAnalyticsView'
import SchoolStudentsList from './views/app/school/SchoolStudentsList'
import SchoolSettingView from './views/app/school/SchoolSettingView'
import SchoolCourseView from './views/app/school/course/SchoolCourseView'

import TasksList from './views/app/task/TasksList'
import TaskView from './views/app/task/TaskView'

import EventsList from './views/app/event/EventsList'
import EventView from './views/app/event/EventView'
import EventRecurringsList from './views/app/event/EventRecurringsList'
import EventRecurringView from './views/app/event/EventRecurringView'

import NotesList from './views/app/note/NotesList'

import MetricsStatsStatsList from './views/app/metricsStats/MetricsStatsStatsList'
import MetricsStatsStatView from './views/app/metricsStats/MetricsStatsStatView'
import MetricsStatsMetricsList from './views/app/metricsStats/MetricsStatsMetricsList'
import MetricsStatsMetricView from './views/app/metricsStats/MetricsStatsMetricView'

const startAppAuthRoute = {name: 'dashboard'}

const routes = [
  {path: '/', name: 'auth', component: AuthView, meta: {guest: true, layout: 'auth'}},
  {path: '/dashboard', name: 'dashboard', component: DashboardView, meta: {guest: false, layout: 'app', menu: 'dashboard'}},
  {path: '/profile', name: 'profile', component: ProfileView, meta: {guest: false, layout: 'app', menu: 'profile'}},
  {path: '/workplace-parameters', name: 'workplace-parameters', component: WorkplaceParametersView, meta: {guest: false, layout: 'app', menu: 'workplace parameters'}},

  {path: '/sys-contractors', name: 'sys-contractors', component: SysContractorsList, meta: {guest: false, layout: 'app', menu: 'sys-contractors'}},
  {path: '/sys-contractor/:uuid', name: 'sys-contractor', component: SysContractorView, meta: {guest: false, layout: 'app', menu: 'sys-contractors'}},

  {path: '/sys-businesses', name: 'sys-businesses', component: SysBusinessesList, meta: {guest: false, layout: 'app', menu: 'sys-businesses'}},
  {path: '/sys-business/:uuid', name: 'sys-business', component: SysBusinessView, meta: {guest: false, layout: 'app', menu: 'sys-businesses'}},

  {path: '/sys-users', name: 'sys-users', component: SysUsersList, meta: {guest: false, layout: 'app', menu: 'sys-users'}},
  {path: '/sys-user/:uuid', name: 'sys-user', component: SysUserView, meta: {guest: false, layout: 'app', menu: 'sys-users'}},

  {path: '/calendar', name: 'calendar', component: CalendarView, meta: {guest: false, layout: 'app', menu: 'calendar'}},

  {path: '/personnel-structures', name: 'personnel-structures', component: PersonnelStructuresList, meta: {guest: false, layout: 'app', menu: 'personnel-structures'}},
  {path: '/personnel-structure/:uuid', name: 'personnel-structure', component: PersonnelStructureView, meta: {guest: false, layout: 'app', menu: 'personnel-structures'}},

  {path: '/users', name: 'users', component: UsersList, meta: {guest: false, layout: 'app', menu: 'users'}},
  {path: '/user/:uuid', name: 'user', component: UserView, meta: {guest: false, layout: 'app', menu: 'users'}},

  {path: '/crm-contractors', name: 'crm-contractors', component: CrmContractorsList, meta: {guest: false, layout: 'app', menu: 'crm'}},
  {path: '/crm-contractor/:uuid', name: 'crm-contractor', component: CrmContractorView, meta: {guest: false, layout: 'app', menu: 'crm'}},

  {path: '/school-run-processes', name: 'school-run-processes', component: SchoolRunProcessesList, meta: {guest: false, layout: 'app', menu: 'school'}},
  {path: '/school-run-process/:uuid', name: 'school-run-process', component: SchoolRunProcessView, meta: {guest: false, layout: 'app', menu: 'school'}},
  {path: '/school-done-processes', name: 'school-done-processes', component: SchoolDoneProcessesList, meta: {guest: false, layout: 'app', menu: 'school'}},
  {path: '/school-done-process/:uuid', name: 'school-done-process', component: SchoolDoneProcessView, meta: {guest: false, layout: 'app', menu: 'school'}},
  {path: '/school-planning', name: 'school-planning', component: SchoolPlanningView, meta: {guest: false, layout: 'app', menu: 'school'}},
  {path: '/school-planning-process/:uuid', name: 'school-planning-process', component: SchoolPlanningProcessView, meta: {guest: false, layout: 'app', menu: 'school'}},
  {path: '/school-students', name: 'school-students', component: SchoolStudentsList, meta: {guest: false, layout: 'app', menu: 'school'}},
  {path: '/school-analytics-timetables', name: 'school-analytics-timetables', component: SchoolAnalyticsTimetablesView, meta: {guest: false, layout: 'app', menu: 'school'}},
  {path: '/school-analytics', name: 'school-analytics', component: SchoolAnalyticsView, meta: {guest: false, layout: 'app', menu: 'school'}},
  {path: '/school-setting', name: 'school-setting', component: SchoolSettingView, meta: {guest: false, layout: 'app', menu: 'school'}},
  {path: '/school-course/:uuid', name: 'school-course', component: SchoolCourseView, meta: {guest: false, layout: 'app', menu: 'school'}},

  {path: '/tasks', name: 'tasks', component: TasksList, meta: {guest: false, layout: 'app', menu: 'tasks'}},
  {path: '/task/:uuid', name: 'task', component: TaskView, meta: {guest: false, layout: 'app', menu: 'tasks'}},

  {path: '/events', name: 'events', component: EventsList, meta: {guest: false, layout: 'app', menu: 'events'}},
  {path: '/event/:uuid', name: 'event', component: EventView, meta: {guest: false, layout: 'app', menu: 'events'}},
  {path: '/event-recurrings', name: 'event-recurrings', component: EventRecurringsList, meta: {guest: false, layout: 'app', menu: 'events'}},
  {path: '/event-recurring/:uuid', name: 'event-recurring', component: EventRecurringView, meta: {guest: false, layout: 'app', menu: 'events'}},

  {path: '/notes', name: 'notes', component: NotesList, meta: {guest: false, layout: 'app', menu: 'notes'}},

  {path: '/metrics-stats-stats', name: 'metrics-stats-stats', component: MetricsStatsStatsList, meta: {guest: false, layout: 'app', menu: 'metrics-stats'}},
  {path: '/metrics-stats-stat/:uuid', name: 'metrics-stats-stat', component: MetricsStatsStatView, meta: {guest: false, layout: 'app', menu: 'metrics-stats'}},
  {path: '/metrics-stats-metrics', name: 'metrics-stats-metrics', component: MetricsStatsMetricsList, meta: {guest: false, layout: 'app', menu: 'metrics-stats'}},
  {path: '/metrics-stats-metric/:uuid', name: 'metrics-stats-metric', component: MetricsStatsMetricView, meta: {guest: false, layout: 'app', menu: 'metrics-stats'}},
]

const router = createRouter({
  history: createMemoryHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  try {
    let nextTo = null

    let user = JSON.parse(localStorage.user ?? '{"isAuth":false,"name":"","avatarText":"","avatarImg":"","routes":[]}')
    switch (true) {
      case false === to.meta.guest:
        switch (true) {
          case false === user.isAuth:
            nextTo = {name: 'auth'}
            break
          case to.name !== startAppAuthRoute.name && user.routes.indexOf(to.name) === -1:
            nextTo = startAppAuthRoute
            break
        }
        break
      case true === to.meta.guest && user.isAuth === true:
        nextTo = startAppAuthRoute
        break
    }

    if (nextTo != null) {
      next(nextTo)
    } else {
      next()
    }
  } catch (e) {
    next({name: 'auth'})
  }
})

router.afterEach((to, from) => {
  localStorage.setItem(
      'router_currentRoute',
      JSON.stringify({
        path: to.path, name: to.name, params: to.params, meta: to.meta
      })
  )
  localStorage.setItem(
      'router_backRoute',
      JSON.stringify({
        path: from.path, name: from.name, params: from.params, meta: from.meta
      })
  )
})

export default router

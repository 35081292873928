<template>
  <div>
    <base-modal
        :show="editQueryModal"
        :is-min-width100p="true"
        title=""
        :is-bg-click-close="false"
        :header="false"
        :footer="true"
        @close="closeSelectModal"
        :is-global-close="isGlobalClose"
    >
      <template v-slot:body>
        <div class="mb-2">
          <div class="text-start mb-4" v-if="!empty(nameTemplates) && false === isLoadingListTemplate">
            <label class="form-label">Шаблоны</label>
            <div class="row">
              <div class="col-md-6 mb-1">
                <multiselect-input
                    v-model="template.uuid"
                    :list="listTemplate"
                    :is-global-close="false"
                    :after-select-option="selectedTemplate"
                />
              </div>
              <div class="col-md-6 mb-1">
                <button type="button" @click="openSaveTemplateModal" class="btn btn-outline-primary me-1" title="Сохранить изменения в шаблон">
                  <i class="fa-solid fa-floppy-disk"></i>
                </button>
                <button v-if="!empty(template.uuid)" type="button" @click="openDeleteTemplateModal" class="btn btn-outline-danger me-1" title="Удалить шаблон">
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-2 text-start">
          <label class="form-label">Условия</label>
          <div v-if="!empty(query)">
            <search-query-builder-group
                :data="query"
                :indexes="[]"
                :fields="fields"
                :add-rule="addRule"
                :add-group="addGroup"
                :update-object="updateObject"
                :delete-object="deleteObject"
                :edit-group-connector="editGroupConnector"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a class="btn btn-light sm-w-100 mt-1 ms-sm-2" @click="clearQuery">Очистить</a>
        <a class="btn btn-primary sm-w-100 mt-1 ms-sm-2" @click="closeSelectModal">ОК</a>
      </template>
    </base-modal>
    <base-modal
        :show="editTemplate.isModal"
        title="Сохранить шаблон"
        :is-bg-click-close="false"
        :header="true"
        :footer="true"
        @close="closeSaveTemplateModal"
        :is-global-close="false"
    >
      <template v-slot:body>
        <search-query-builder-search-template-view
            :uuid="editTemplate.uuid"
            :list-template="listTemplate"
            :close="closeSaveTemplateModal"
            :update-parent="afterSaveTemplate"
            :query-json="query"
            :name-templates="nameTemplates"
        />
      </template>
    </base-modal>
    <confirm-action-modal
        :show="isConfirmDeleteTemplateModal"
        :text="'Удалить шаблон ' + getValueEnums(listTemplate, template.uuid, 'name') + '?'"
        :btn-text="'Удалить'" :btn-type="'danger'"
        :confirm-action="templateDelete"
        :is-global-close="false"
        @close="isConfirmDeleteTemplateModal = false"
    />

    <div @click="openModel" class="label-query-builder-container">
      <div class="label-query-builder-container-indicator">
        <i class="fa-solid fa-filter"></i>
      </div>
      <div v-if="isLoadingListTemplate">
        <div class="spinner-grow spinner-grow-sm text-primary" role="status">
          <span class="visually-hidden">Загрузка условий ...</span>
        </div>
        Загрузка условий ...
      </div>
      <div v-else>
        <div class="label-query-builder-template" v-if="!empty(template.uuid)">
          <span class="text-secondary me-1">шаблон</span>
          {{ getValueEnums(listTemplate, template.uuid, 'name') }}
        </div>
        <div v-else v-html="labelQuery"></div>
      </div>
    </div>
  </div>
</template>
<script>

import BaseModal from '@/components/BaseModal'
import SearchQueryBuilderGroup from '@/components/searchQueryBuilder/SearchQueryBuilderGroup'
import MultiselectInput from '@/components/form/MultiselectInput'
import SearchQueryBuilderSearchTemplateView
  from '@/components/searchQueryBuilder/SearchQueryBuilderSearchTemplateView'
import ConfirmActionModal from "@/components/ConfirmActionModal.vue";

export default {
  name: 'SearchQueryBuilder',
  components: {ConfirmActionModal, SearchQueryBuilderSearchTemplateView, MultiselectInput, SearchQueryBuilderGroup, BaseModal},
  props: {
    modelValue: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      required: true,
    },
    isGlobalClose: {
      type: Boolean,
      default: true,
    },
    afterEditQuery: {
      type: Function,
      default: function () {}
    },
    nameTemplates: {
      type: String,
      default: '',
    },
  },
  watch: {
   'modelValue': function () {
     this.setValue()
   },
/*
   'query': function () {
     this.setModelValue()
   }
 */
  },
  mounted: function() {
    this.setValue()
    this.loadListTemplate()
  },
  data() {
    return {
      isConfirmDeleteTemplateModal: false,
      template: {
        uuid: '',
      },
      isLoadingListTemplate: false,
      listTemplate: [],

      editQueryModal: false,

      query: {},
      labelQuery: '',
      newGroup: {
        connector: 'and',
        rules: [],
      },
      newRule: {
        condition: null,
        field: null,
        value: null,
      },

      confValues: {
        is_empty: false,
        is_not_empty: false,
      },

      editTemplate: {
        isModal: false,
        uuid: 'new',
      },

      confCondition: {
        equal: 'равно',
        not_equal: 'не равно',
        less: 'меньше',
        less_or_equal: 'меньше или равно',
        greater: 'больше',
        greater_or_equal: 'больше или равно',
        begins_with: 'начинается с',
        not_begins_with: 'не начинается с',
        contains: 'содержит',
        not_contains: 'не содержит',
        ends_with: 'оканчивается на',
        not_ends_with: 'не оканчивается на',
        is_empty: 'пусто',
        is_not_empty: 'не пусто',
      },
    }
  },
  methods: {
    loadListTemplate: function () {
      if (!this.empty(this.nameTemplates)) {
        this.isLoadingListTemplate = true
        let self = this
        window.axios.post(
            `${this.globalVar.app.api.url}/search-templates?${(new Date()).getTime()}`,
            {search: {type: this.nameTemplates}, sort: {}}
        )
            .then(response => {
              self.listTemplate = response.data.items
              self.template.uuid = localStorage.getItem(self.nameTemplates + '.template')
            })
            .catch((error) => {
              console.log(error, error.data)
            })
            .finally(() => {
              this.isLoadingListTemplate = false
            })
      }
    },

    selectedTemplate: function () {
      localStorage.setItem(this.nameTemplates + '.template', this.template.uuid)
      let queryJson = this.getValueEnums(this.listTemplate, this.template.uuid, 'query_json')
      this.query = JSON.parse(JSON.stringify(queryJson))
      this.initQuery()
      this.setLabelQuery()
    },

    setLabelQuery: function () {
      let rules = []
      for (let i = 0; i < this.query.rules.length; i++) {
        rules.push(
            this.getLabelObject(this.query.rules[i])
        )
      }
      this.labelQuery = rules.join(
          '<div class="label-query-builder-connector">' + ('and' === this.query.connector ? 'и' : 'или') + '</div>'
      )
    },
    getLabelObject: function (object) {
      if (undefined === object.rules) {
        let fieldType = this.getValueEnums(this.fields, object.field, 'type')
        let fieldValues = this.getValueEnums(this.fields, object.field, 'values')

        let value = object.value + ''
        if ('select' === fieldType || 'selectDate' === fieldType) {
          value = this.getValueEnums(fieldValues, object.value, 'name')
        }

        if ('bool' === fieldType) {
          value = 'true' === object.value ? fieldValues.titleTrue : fieldValues.titleFalse

          return '<div class="label-query-builder-rule">'
              + this.getValueEnums(this.fields, object.field, 'name')
              + '<div class="label-query-builder-condition">-</div>'
              + '<div class="label-query-builder-value">' + value + '</div>'
              + '</div>'
        }

        if ('date' === fieldType) {
          value = this.stringDateYmdToDmy(object.value)
        }

        return '<div class="label-query-builder-rule">'
            + this.getValueEnums(this.fields, object.field, 'name')
            + (this.confCondition[object.condition] ? '<div class="label-query-builder-condition">' + this.confCondition[object.condition] + '</div>' : '')
            + '<div class="label-query-builder-value">' + value + '</div>'
            + '</div>'
      } else {
        let rules = []
        for (let i = 0; i < object.rules.length; i++) {
          rules.push(
              this.getLabelObject(object.rules[i])
          )
        }
        return '<div class="label-query-builder-group">'
            + rules.join('<div class="label-query-builder-connector">' + ('and' === object.connector ? 'и' : 'или') + '</div>')
            + '</div>'
      }
    },

    setValue: function () {
      this.query = JSON.parse(JSON.stringify(this.modelValue))

      this.initQuery()
      this.setLabelQuery()
    },
    openModel: function () {
      if (false === this.disabled) {
        this.editQueryModal = true
        this.emitter.emit('modal', {event: 'open'})
      }
    },
    initQuery: function () {
      if (this.empty(this.query)) {
        this.query = {
          connector: 'and',
          rules: [],
        }
      }
    },

    addRule: function (indexes) {
      this.addObject(indexes, 'rule')
    },
    addGroup: function (indexes) {
      this.addObject(indexes, 'group')
    },
    addObject: function (indexes, type) {
      this.clearTemplate()

      let newObject = JSON.parse(JSON.stringify('rule' === type ? this.newRule : this.newGroup))

      if (this.empty(indexes)) {
        this.query.rules.push(newObject)
      } else {
        let rules = this.query.rules[indexes[0]].rules

        for (let i = 1; i < indexes.length; i++) {
          rules = rules[indexes[i]].rules
          if (i === indexes.length - 1) {
            rules.push(newObject)
            this.setLabelQuery()
            return true
          }
        }
        rules.push(newObject)

        this.setLabelQuery()
        return true
      }
    },
    updateObject: function (indexes, condition, field, value) {
      if (1 === indexes.length) {
        this.query.rules[indexes[0]].condition = condition + ''
        this.query.rules[indexes[0]].field = field + ''
        this.query.rules[indexes[0]].value = value + ''
      } else {
        let object = this.query.rules[indexes[0]]
        for (let i = 1; i < indexes.length; i++) {
          if (i === indexes.length - 1) {
            object.rules[indexes[i]].condition = condition + ''
            object.rules[indexes[i]].field = field + ''
            object.rules[indexes[i]].value = value + ''
          } else {
            object = object.rules[indexes[i]]
          }
        }
      }

      this.clearTemplate()
      this.setLabelQuery()
    },
    deleteObject: function (indexes) {
      if (1 === indexes.length) {
        this.query.rules.splice(indexes[0], 1)
        if (1 === this.query.rules.length) {
          this.query.rules.connector = 'and'
        }
      } else {
        let object = this.query.rules[indexes[0]]
        for (let i = 1; i < indexes.length; i++) {
          if (i === indexes.length - 1) {
            object.rules.splice(indexes[i], 1)
            if (1 === object.rules.length) {
              object.connector = 'and'
            }
          } else {
            object = object.rules[indexes[i]]
          }
        }
      }

      this.clearTemplate()
      this.setLabelQuery()
    },
    editGroupConnector: function (indexes, value) {
      if (0 === indexes.length) {
        this.query.connector = value + ''
      } else if (1 === indexes.length) {
        this.query.rules[indexes[0]].connector = value + ''
      } else {
        let object = this.query.rules[indexes[0]]
        for (let i = 1; i < indexes.length; i++) {
          if (i === indexes.length - 1) {
            object.rules[indexes[i]].connector = value + ''
          } else {
            object = object.rules[indexes[i]]
          }
        }
      }

      this.clearTemplate()
      this.setLabelQuery()
    },

    setModelValue() {
      let self = this

      setTimeout(function () {
        self.$emit('update:modelValue', self.query)
        self.afterEditQuery()
      }, 20)
    },

    openSaveTemplateModal: function () {
      this.editTemplate.uuid = 'new'
      if (false === this.empty(this.template.uuid)) {
        this.editTemplate.uuid = this.template.uuid + ''
      }
      this.editTemplate.isModal = true
    },
    templateDelete: function () {
      window.axios.delete(`${this.globalVar.app.api.url}/search-template?uuid=${this.template.uuid}&${(new Date()).getTime()}`).then(() => {
        this.isConfirmDeleteTemplateModal = false
        this.clearTemplate()
        localStorage.removeItem(this.nameTemplates + '.query')
        this.loadListTemplate()
      });
    },
    openDeleteTemplateModal: function () {
      this.isConfirmDeleteTemplateModal = true
    },
    closeSaveTemplateModal: function () {
      this.editTemplate.isModal = false
    },
    afterSaveTemplate: function () {
      this.loadListTemplate()
    },
    clearTemplate: function () {
      this.template.uuid = ''
      localStorage.removeItem(this.nameTemplates + '.template')
    },
    clearQuery: function () {
      this.template.uuid = ''
      this.query = null
      this.initQuery()
      this.setModelValue()
      this.editQueryModal = false
      if (true === this.isGlobalClose) {
        this.emitter.emit('modal', {event: 'close'})
      }
    },

    closeSelectModal: function () {
      this.setModelValue()
      this.editQueryModal = false
      if (true === this.isGlobalClose) {
        this.emitter.emit('modal', {event: 'close'})
      }
    },
  }
}
</script>

<style>
.label-query-builder-container {
  position: relative;
  cursor: pointer;
  border: 1px solid var(--bs-border-color);
  padding: 6px 10px 6px 30px;
  min-height: 38px;
}

.label-query-builder-container-indicator {
  position: absolute;
  left: 10px;
  top: 6px;
  color: rgb(var(--bs-secondary-rgb));
}

.label-query-builder-template {

}

.label-query-builder-group {
  display: inline-block;
  border: 1px solid var(--bs-border-color);
  padding: 0 6px;
  border-radius: 6px;
}

.label-query-builder-connector {
  display: inline-block;
  color: rgb(var(--bs-secondary-rgb));
  padding: 0 6px;
}

.label-query-builder-rule {
  display: inline-block;
  padding: 0;
}

.label-query-builder-condition {
  display: inline-block;
  color: rgb(var(--bs-secondary-rgb));
  padding: 0 6px;
}

.label-query-builder-value {
  display: inline-block;
}

.query-builder-item {
  margin-top: 6px;
}

.query-builder-item > .query-builder-item:first-child {
  margin-top: 0;
}

.query-builder-group {
  border: 1px solid var(--bs-border-color);
  padding: 2px 10px;
}

.query-builder-rule {
  position: relative;
}
.query-builder-rule-btn-delete {
  position: absolute;
  right: 0;
  top: 6px;
}

.query-builder-rule-fields {
  margin-right: 45px;
}
</style>

<style scoped>
</style>

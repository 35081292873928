<template>
  <div class="page-list">
    <metrics-stats-top-tabs />

    <page-top-bar
        :title="title"
        subtitle=""
        :buttons="pageButtons"
    />

    <div class="page-list-content mt-3">
      <div class="row px-2">
        <div
            :class="('oneValue' === item.type_graph ? 'col-sm-6 col-lg-3' : ('pie' === item.type_graph ? 'col-sm-6 col-lg-3' : 'col-md-6')) + ' mb-3'"
            v-for="(item, i_item) in items"
            :key="'list.' + listName + '.' + i_item"
        >
          <metrics-stats-stat-chart-view :chart-data="item" :is-edit="true" />
        </div>
      </div>
    </div>
    <page-list-paginator
        :paginator="paginator"
        :link-page="paginatorLinkPage_click"
    />
  </div>
</template>

<script>

import {listMixin} from '@/mixins/listMixin'
import PageListPaginator from '@/components/PageListPaginator'
import MetricsStatsTopTabs from '@/views/app/metricsStats/components/MetricsStatsTopTabs'
import PageTopBar from '@/components/PageTopBar'
import MetricsStatsStatChartView from '@/components/MetricsStatsStatChartView'

export default {
  name: 'MetricsStatsStatsList',
  components: {MetricsStatsStatChartView, PageTopBar, MetricsStatsTopTabs, PageListPaginator},
    mixins: [listMixin],
  data() {
    return {
      listName: 'MetricsStatsStatsList',
      listUrl: {string: '/metrics-stats-stats', params:[]},
      itemNameRoute: 'metrics-stats-stat',
      pageButtons: [],

      listTabs: [],
      title: '',

      listSearch: {},
      listSearchDefault: {},
    }
  },
  methods: {
    afterLoad: function () {
      let self = this
      self.pageButtons = []
      if (-1 !== self.buttons.indexOf('addStat')) {
        self.pageButtons.push({
          name: 'Добавить статистику',
          class: 'btn-primary', click: function() {
            self.toRoute({name: self.itemNameRoute, params: {uuid: 'new'}})
          }
        })
      }
    },
  }
}
</script>

<style scoped>
.td-description {
  width: 20%;
}

.div-users {
  max-height: 38px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

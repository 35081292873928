<template>
  <page-top-tabs class="mt-2" :tabs="tabs" />
</template>
<script>

import PageTopTabs from '@/components/PageTopTabs'

export default {
  name: 'MetricsStatsTopTabs',
  components: {PageTopTabs},
  data() {
    return {
      tabs: [],
    }
  },
  mounted: function() {
    let self = this

    this.tabs = []
    if (this.hasRoute('metrics-stats-stats')) {
      this.tabs.push({
        name: '<i class="fa-solid fa-chart-pie"></i> Статистики',
        isDisabled: false,
        isActive: 'metrics-stats-stats' === self.$route.name,
        click: function () {self.toRoute({name: 'metrics-stats-stats'})},
      })
    }
    if (this.hasRoute('metrics-stats-metrics')) {
      this.tabs.push({
        name: '<i class="fa-solid fa-swatchbook"></i> Метрики',
        isDisabled: false,
        isActive: 'metrics-stats-metrics' === self.$route.name,
        click: function () {self.toRoute({name: 'metrics-stats-metrics'})},
      })
    }
  },
  methods: {
    hasRoute(routeName) {
      let isShow = false
      this.globalVar.app.user.routes.forEach(function (userRoute) {
        if (userRoute === routeName) {
          isShow = true
        }
      })
      return isShow
    },
  }
}
</script>

<style scoped>
</style>

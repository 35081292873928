window.axios = require('axios')
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.withCredentials = true

import {globalVar} from './globalVar'

import { createApp } from 'vue'
import App from './App.vue'
import './sw/regSW'

import router from './router'

import {swMixin} from './mixins/swMixin'
import {functionsMixin} from './mixins/functionsMixin'
import {webViewMixin} from './mixins/webViewMixin'
import {dbMixin} from './mixins/dbMixin'
import {globalMixin} from './mixins/globalMixin'

let app = createApp(App)
app.mixin(swMixin)
app.mixin(functionsMixin)
app.mixin(webViewMixin)
app.mixin(dbMixin)
app.mixin(globalMixin)

app.config.productionTip = false
app.config.globalProperties.globalVar = globalVar

import {enums} from './enums/enums'
app.config.globalProperties.enums = enums

app.use(router)

import mitt from 'mitt'
const emitter = mitt()
app.config.globalProperties.emitter = emitter

import VueDateWheel from 'vue-datewheel'
import 'vue-datewheel/dist/style.css'
app.component('VueDateWheel', VueDateWheel)

import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'
app.use(VueChartkick)

import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import './assets/all.css'
import './assets/colors.css'

app.mount('#app')

import 'bootstrap/dist/js/bootstrap.js'

<template>
  <div class="card border-0 shadow">
    <div class="card-body position-relative">
      <div class="row">
        <div class="col-4">
          <div class="d-inline-block border-radius-10 text-white" :style="'border: 2px solid '+chartData.icon_color">
          <span class="chart-icon"
                :style="'background-color:'+chartData.icon_color + ';opacity: 0.9;'"
          ><i :class="chartData.icon"></i></span>
          </div>
        </div>
        <div class="col-8 fs-14px text-end">
          <b>{{ chartData.name }}</b>
        </div>
      </div>
      <div v-if="!empty(chartData.cache_values) && !empty(chartData.cache_values.data)">
        <chart-view
            :chart-options="chartData.options"
            :chart-data="chartData.cache_values.data"
            :chart-x-labels="chartData.cache_values.labels"
            :type="chartData.type_graph"
        />
      </div>
      <button
          v-if="isEdit && hasRoute('metrics-stats-stat')"
          class="btn btn-link position-absolute end-0 bottom-0"
          @click="toRoute({name: 'metrics-stats-stat', params: {uuid: chartData.uuid}})"
      >
        <i class="fa-solid fa-gear"></i>
      </button>
    </div>
  </div>
</template>
<script>

import ChartView from '@/components/СhartView'

export default {
  name: 'MetricsStatsStatChartView',
  components: {ChartView},
  props: {
    chartData: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    hasRoute(routeName) {
      let isShow = false
      this.globalVar.app.user.routes.forEach(function (userRoute) {
        if (userRoute === routeName) {
          isShow = true
        }
      })
      return isShow
    },
  }
}
</script>

<style scoped>

.chart-icon {
  font-size: 24px;
  padding: 6px 12px;
  border-radius: 10px;
}

</style>